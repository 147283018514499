var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          attrs: {
            "is-mask": "",
            "display-btn": "",
            title: "详情",
            visible: _vm.visible,
            size: "small",
            "z-index": 3,
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form-model",
                {
                  attrs: {
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c("a-form-model-item", { attrs: { label: "姓名" } }, [
                    _vm._v(" " + _vm._s(_vm.quitDetailsData.staffName) + " "),
                  ]),
                  _c("a-form-model-item", { attrs: { label: "离职日期" } }, [
                    _vm._v(" " + _vm._s(_vm.quitDetailsData.leaveDate) + " "),
                  ]),
                  _c("a-form-model-item", { attrs: { label: "离职方式" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.quitDetailsData.handleTypeName) + " "
                    ),
                  ]),
                  _c("a-form-model-item", { attrs: { label: "离职原因" } }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.quitDetailsData.leaveReasons)),
                    ]),
                  ]),
                  _c("a-form-model-item", { attrs: { label: "离职备注" } }, [
                    _c("div", [_vm._v(_vm._s(_vm.quitDetailsData.remark))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }